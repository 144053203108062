<template>
  <!-- 新增/编辑 大屏 -->
  <el-dialog
    :title="editType === 1 ? '新增大屏' : '编辑大屏'"
    :visible.sync="dialogVisible"
    width="560px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onClose"
  >
    <el-form ref="paramsFormRef" label-width="100px" :model="paramsForm" :rules="rules">
      <el-form-item label="机构名称" prop="name">
        <el-input v-model.trim="paramsForm.name" placeholder="请输入机构名称"></el-input>
      </el-form-item>
      <el-form-item label="系统名称" prop="appName">
        <el-input v-model.trim="paramsForm.appName" placeholder="请输入系统名称"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onClose">取消</el-button>
      <el-button type="primary" :loading="submitLoading" @click="onSubmit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { addOrg, editOrg } from '@/api/main/adminMana';

export default {
  data() {
    return {
      editType: 1, // 1新增 2编辑
      dialogVisible: false,
      submitLoading: false,
      rules: {
        name: [
          { required: true, message: '请输入机构名称', trigger: 'blur' },
          { max: 50, message: '长度在 50 个字符以内', trigger: 'blur' }
        ],
        appName: [
          { required: true, message: '请输入系统名称', trigger: 'blur' },
          { max: 50, message: '长度在 50 个字符以内', trigger: 'blur' }
        ]
      },
      paramsForm: {
        name: '',
        appName: '',
      }
    };
  },
  methods: {
    open(row) {
      this.dialogVisible = true;
      if (row) {
        this.editType = 2
        this.$nextTick(() => {
          this.paramsForm = Object.assign(this.paramsForm, {
            id: row.id,
            name: row.name,
            appName: row.appName,
          });
        });
      }
    },
    onClose() {
      this.dialogVisible = false;
      this.$refs.paramsFormRef.resetFields();
      this.paramsForm.id = '';
      this.editType = 1
    },
    onSubmit() {
      this.$refs.paramsFormRef.validate(valid => {
        if (!valid) return;
        this.submitLoading = true;
        let url = ''
        this.editType === 1 ? url = addOrg(this.paramsForm) : url = editOrg(this.paramsForm)
          url.then(res => {
            const { id } = this.paramsForm;
            this.$message.success(id ? '编辑成功！' : '新增成功！');
            this.$emit('onSuccess', id);
            this.onClose();
          })
          .finally(() => {
            this.submitLoading = false;
          });
      });
    }
  }
};
</script>
