<template>
  <div class="equip-container">
    <div class="title">系统机构管理</div>
    <div class="query-nav">
      <el-form ref="queryFormRef" inline :model="queryForm" label-width="100px">
        <el-form-item prop="name">
          <el-input v-model.trim="queryForm.name" placeholder="请输入机构名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch">查询</el-button>
          <el-button @click="handleReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="buts">
      <el-button type="primary" icon="el-icon-plus" @click="$refs.infoDialogRef.open()">新建</el-button>
    </div>
    <div class="table">
      <el-table
        stripe
        size="medium"
        :data="orgList"
        v-loading="tableLoading"
      >
        <el-table-column align="center" width="80" type="index" fixed label="序号"></el-table-column>
        <el-table-column align="center" width="240" label="机构名称" show-overflow-tooltip prop="name"></el-table-column>
        <el-table-column align="center" width="240" label="系统名称" prop="appName" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" label="管理员姓名" show-overflow-tooltip prop="adminUserName">
          <template slot-scope="{ row }">{{ row.adminUserName || '--'}}</template>
        </el-table-column>
        <el-table-column align="center" label="管理员电话" show-overflow-tooltip prop="adminPhone">
          <template slot-scope="{ row }">{{ row.adminPhone || '--'}}</template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="240" fixed="right">
          <template slot-scope="{ row }">
            <div class="comm-operation">
              <span class="edit-btn mr-16" @click="$refs.infoDialogRef.open(row)">修改</span>
              <span class="edit-btn" @click="$refs.dispatchDialogRef.open(row)">分配管理员</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页区域 -->
    <div class="page">
      <el-pagination
        background
        :total="total"
        :current-page="queryForm.pageNo"
        :page-size="queryForm.pageSize"
        :page-sizes="[10, 20, 30, 50]"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, prev, pager, next, sizes"
      ></el-pagination>
    </div>
    <!-- 新增/编辑 对话框 -->
    <InfoDialog ref="infoDialogRef" @onSuccess="handleSuccess" />
    <!-- 分配管理员 对话框 -->
    <dispatch-dialog ref="dispatchDialogRef" @ok="handleOk"></dispatch-dialog>
  </div>
</template>

<script>
import { getOrgList } from '@/api/main/adminMana';
import InfoDialog from './infoDialog.vue';
import dispatchDialog from '@/views/main/adminMana/orgMana/dispatchDialog';
export default {
  components: {
    InfoDialog,
    dispatchDialog
  },
  data() {
    return {
      total: 0,
      queryForm: {
        name: '',
        pageNo: 1,
        pageSize: 10
      },
      orgList: [],
      tableLoading: false,
    };
  },
  created() {
    this.getOrgListData();
  },
  methods: {
    // 获取角色列表
    getOrgListData() {
      this.tableLoading = true;
      getOrgList(this.queryForm)
        .then(res => {
          this.orgList = res.data.records;
          this.total = res.data.total;
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    // 搜索
    handleSearch() {
      this.queryForm.pageNo = 1;
      this.getOrgListData();
    },
    // 重置
    handleReset() {
      this.queryForm.pageNo = 1;
      this.queryForm.pageSize = 10;
      this.queryForm.name = '';
      this.getOrgListData();
    },
    // 分页
    handleSizeChange(val) {
      this.queryForm.pageNo = 1;
      this.queryForm.pageSize = val;
      this.getOrgListData();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNo = val;
      this.getOrgListData();
    },
    // 新增/编辑成功
    handleSuccess(data) {
      // 编辑成功，原地刷新；新增成功，重置首页
      data ? this.getOrgListData() : this.handleReset();
    },
    handleOk() {
      this.getOrgListData()
    },
  }
};
</script>

<style lang="less" scoped>
.equip-container {
  .page {
    margin: 16px 0;
    float: right;
  }
}
.query-nav {
  border-bottom: 1px solid #dcdfe7;
  border-top: 1px solid #dcdfe7;
  padding-top: 24px;
}
.buts {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  span {
    font-size: 14px;
    font-weight: 400;
    color: #117bf7;
  }
}
.table {
  margin-top: 12px;
}
.title {
  padding: 0 16px 12px;
  font-size: 16px;
  font-weight: bold;
}
</style>
