<template>
  <el-dialog
    title="分配管理员"
    :visible.sync="dialogVisible"
    width="560px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onCancel"
  >
    <el-form ref="paramsFormRef" label-width="120px" :model="paramsForm">
      <el-form-item label="大屏名称" prop="name">
        <el-input v-model="paramsForm.name" disabled></el-input>
      </el-form-item>
      <el-form-item label="分配管理员">
        <el-select v-model="paramsForm.userIdsList" multiple placeholder="请选择">
          <el-option v-for="item in users" :key="item.userId" :label="item.name" :value="item.userId">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onCancel">取消</el-button>
      <el-button type="primary" :loading="submitLoading" @click="onConfirm">保存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import store from '@/utils/store';
import { getGrantUser, grantOrgUser } from '@/api/main/adminMana'
export default {
  data() {
    return {
      dialogVisible: false,
      submitLoading: false,
      users: [],
      paramsForm: {
        name: '',
        userIdsList: []
      },
    };
  },
  methods: {
    open(row) {
      this.paramsForm.name = row.name
      this.paramsForm.orgId = row.id
      getGrantUser({orgId: row.id}).then(res => {
        let data = res.data;
        this.users = data;
        this.paramsForm.userIdsList = data.filter(item => item.checked).map(item => item.userId);
      }).finally(() => {
        this.dialogVisible = true
      })
    },
    // 取消
    onCancel() {
      this.dialogVisible = false;
    },
    // 确定
    onConfirm() {
      this.submitLoading = true;
      delete this.paramsForm.name
      grantOrgUser(this.paramsForm)
        .then(res => {
          this.$message.success('分配成功！');
          this.onCancel();
          this.$emit('ok',1)
        })
        .finally(() => {
          this.submitLoading = false;
        });
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-loading-mask {
  background-color: #fff;
}

.el-tree {
  height: 600px;
  overflow: auto
}
</style>
